import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./ServicePage.css";
import ScrollReveal from "../ScrollReveal/ScrollReveal";
import ContactCTA from "../ContactCTA/ContactCTA";
import ExpandableBlock from "./ExpandableBlock/ExpandableBlock";
import ServiceGraph from "./ServiceGraph/ServiceGraph";
import api from "../../api";
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

const ServiceTemplate = ({ isCategoryPage, staticData }) => {
    const { slug } = useParams();
    const [data, setData] = useState(staticData || null);
    const [loading, setLoading] = useState(isCategoryPage || !staticData);
    const secu = slug === "firewall" ? true : slug === "sauvegarde" ? true : slug === "email-security" ? true : false
    useEffect(() => {
        if (!isCategoryPage) {
            const fetchData = async () => {
                try {
                    const response = await api.get(`/service/${slug}`);
                    setData(response.data)
                } catch (error) {
                    console.error("Erreur lors de la récupération des données :", error);
                }
            };
            fetchData();
        }
        setLoading(false);
    }, [slug, isCategoryPage]);

    if (loading) return <p className="loading">Chargement en cours...</p>;
    if (!data) return <p className="error">Aucune donnée trouvée.</p>;

    return (
        <div className="service-page">
            <Helmet>
                <title>Service - Tout pour répondre à votre besoin !</title>
                <meta name="description" content="Infogérance, matériel, fibre , hébergement, cybersécurité tous nos services afin de répondre à vos besoins informatique" />
            </Helmet>
            <div className={`bg-service bg-${slug}`}></div>
            <ScrollReveal>
                <div className="service-header">
                    <h1>{data.name}</h1>
                </div>
            </ScrollReveal>

            <div className="service-container">
                <div className="service-body">
                    <div className="service-def">
                        {secu && (
                            <div className="service-secu">
                                <h2>Qu'est ce que La Cybersécurité</h2>
                                <p>La cybersécurité englobe l'ensemble des mesures, technologies et pratiques visant à protéger les systèmes informatiques, les réseaux, les programmes et les données contre les attaques, les dommages ou les accès non autorisés.</p>
                                <h3>Pourquoi ça existe ?</h3>
                                <ul>
                                    <li>
                                        Protéger les données sensibles de l'entreprise et de ses clients.
                                    </li>
                                    <li>
                                        Prévenir les pertes financières liées aux cyberattaques.
                                    </li>
                                    <li>
                                        Maintenir la confiance des clients et partenaires.
                                    </li>
                                </ul>
                            </div>
                        )}
                        <h2>Qu'est-ce que {data.name} ?</h2>
                        <p>{data.definition}</p>

                        {data.points && (
                            <>
                                <h3>Pourquoi ça existe ?</h3>
                                <ul>
                                    {data.points.map((point, index) => (
                                        <li key={index}>{point.point}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {secu && (
                            <div>
                                <h2>Un ensemble de solutions complémentaires</h2>
                                <p>La cybersécurité est un domaine vaste qui comprend plusieurs solutions distinctes, chacune jouant un rôle crucial dans la protection globale de votre infrastructure informatique. Chez Com'Unity, nous proposons une approche modulaire, avec des pages dédiées pour chaque solution majeure :</p>
                                <ul>
                                    {slug !== "firewall" && (<li><u>Firewall</u> : Protection avancée contre les intrusions et contrôle du trafic réseau.</li>)}
                                    {slug !== "sauvegarde" && (<li><u>Sauvegarde (Backup)</u> : Sécurisation des données critiques et plans de reprise d'activité.</li>)}
                                    {slug !== "email-security" && (<li><u>Sécurité Email</u> : Défense contre le spam, le phishing et les emails frauduleux, incluant des programmes de sensibilisation des utilisateurs.</li>)}
                                    {slug !== "securite" && (<li><u>RGPD</u> : </li>)}
                                </ul>
                            </div>
                        )}


                    </div>

                    {/* 🔥 Raisons de choisir ce service */}
                    {data.reasons && (
                        <div className="service-bloc">
                            <h2>Les avantages pour vous</h2>
                            <div className="reasons-grid">
                                <div className="reasons-row-top">
                                    {data.reasons.slice(0, 3).map((reason, index) => (
                                        <div className="reasons-container">
                                            <img src="/img/Capture.png" alt="avantages" className="reasons-img" />
                                            <div key={index} className="reason-card">
                                                <h3>{reason.title}</h3>
                                                <p>{reason.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="reasons-row-bottom">
                                    {data.reasons.slice(3, 5).map((reason, index) => (
                                        <div className="reasons-container">
                                            <img src="/img/Capture.png" alt="avantages" className="reasons-img" />
                                            <div key={index} className="reason-card">
                                                <h3>{reason.title}</h3>
                                                <p>{reason.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* 🎯 Composant dynamique supplémentaire */}
                    {isCategoryPage && <ServiceGraph services={data.services} />}

                    {/* 📌 Liste Dépliable */}
                    <div className="service-valeurs-ajoute">
                        <h2>Nôtre valeur ajoutée</h2>
                        <p>{data.valeur_ajoutee}</p>
                        <ExpandableBlock items={isCategoryPage ? data.services[0].valeurs : data.valeurs} imageUrl={"/img/Capture.png"} />
                    </div>

                    <div className="service-parcours">
                        <h2>L'accompagnement Com'Unity : 4 Étapes Clients Clair</h2>
                        <img alt="Parcours Com'Unity" src="/img/accompagnement-com-unity.svg" />
                    </div>
                    {!isCategoryPage && (
                        data.relatedServices.length > 0 && (
                            <div className="related-services">
                                <h2>Autres services de cette catégorie</h2>
                                <div className="related-buttons">
                                    {data.relatedServices.map((related) => (
                                        <div key={related.id} className="related-service">
                                            <Link to={`/${related.slug}`} className="related-service-button">
                                                {related.name}
                                            </Link>
                                            <p className="related-service-description">{related.description}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    )}

                </div>
            </div>

            <ContactCTA isVisible={true} />
        </div>
    );
};

export default ServiceTemplate;
