import React, { useState } from "react";
import "./RDV.css";
import CustomDatePicker from './CustomDatePicker/CustomDatePicker';
import CustomTimePicker from './CustomTimePicker/CustomTimePicker';

const Rdv = ({ isOpen, onClose, appointmentType }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedTime, setSelectedTime] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    phone: "",
    location: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateSelection = (date) => {
    console.log("Date sélectionnée :", date);
  };

  const handleTimeSelection = (time) => {
    setSelectedTime(time);
    console.log("Heure sélectionnée :", time);
  };

  const isStep1Valid = () => {
    return selectedTime !== "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      time: selectedTime,
      type: appointmentType,
      details: formData,
    });
    alert("Rendez-vous soumis avec succès !");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="rdv-modal-overlay">
      <div className="rdv-container">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Planifier un Rendez-vous</h2>
        {/* Étape 1 : Sélection de la date et de l'heure */}
        {currentStep === 1 && (
          <div className={`step${currentStep === 1 ? ' active' : ''} step-2`}>
            <h3>Étape 1 : Sélectionnez une date et une heure</h3>
            <div className="rdv-picker">
              <CustomDatePicker onDateSelect={handleDateSelection} />
              <CustomTimePicker onTimeSelect={handleTimeSelection} typeRdv={appointmentType} />
            </div>
            <div className="step-buttons">
              <button className="submit-button" onClick={() => setCurrentStep(2)} disabled={!isStep1Valid()}>
                Suivant
              </button>
            </div>
          </div>
        )}

        {/* Étape 2 : Informations sur le client */}
        {currentStep === 2 && (
          <form onSubmit={handleSubmit} className={`step${currentStep === 2 ? ' active' : ''} step-2 rdv-form`}>
            <h3>Étape 2 : Vos Informations</h3>
            <div className="form-group">
              <label htmlFor="firstName">Prénom :</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Nom :</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Adresse Email :</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="company">Nom de la Société :</label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
                required
              />
            </div>

            {appointmentType === "physique" && (
              <div className="form-group">
                <label htmlFor="location">Lieu :</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  placeholder="Lieu du rendez-vous"
                  required
                />
              </div>
            )}
            {appointmentType === "visio" && (
              <div className="form-group">
                <label htmlFor="phone">Numéro de Téléphone :</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Téléphone à appeler"
                  required
                />
              </div>
            )}
            <div className="step-buttons">
              <button className="back-button" onClick={() => setCurrentStep(1)}>
                Retour
              </button>
              <button type="submit" className="submit-button">
                Soumettre
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Rdv;