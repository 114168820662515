import React, { useState, useEffect } from "react";
import "./CustomDatePicker.css";

const CustomDatePicker = ({ onDateSelect }) => {
  const today = new Date();
  const [selectedYear, setSelectedYear] = useState(today.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(today.getMonth());
  const [selectedDate, setSelectedDate] = useState();
  const [daysInMonth, setDaysInMonth] = useState([]);

  useEffect(() => {
    const date = new Date(selectedYear, selectedMonth + 1, 0); // Dernier jour du mois
    const totalDays = date.getDate();
    const days = Array.from({ length: totalDays }, (_, i) => i + 1);
    setDaysInMonth(days);
  }, [selectedYear, selectedMonth]);

  const handleDateSelect = (day) => {
    const selected = new Date(selectedYear, selectedMonth, day);
    if (selected >= today) {
      setSelectedDate(day);
      onDateSelect(selected); // Retourne la date sélectionnée
    }
  };

  const handleMonthChange = (offset) => {
    const newDate = new Date(selectedYear, selectedMonth + offset);
    if (newDate >= today) {
      setSelectedYear(newDate.getFullYear());
      setSelectedMonth(newDate.getMonth());
    }
  };

  return (
    <div className="custom-date-picker">
      <div className="header">
        <button
          className="nav-button"
          onClick={() => handleMonthChange(-1)}
          disabled={new Date(selectedYear, selectedMonth - 1) < today}
        >
          &lt;
        </button>
        <div className="month-year">
          {new Date(selectedYear, selectedMonth).toLocaleString("fr-FR", {
            month: "long",
            year: "numeric",
          })}
        </div>
        <button className="nav-button" onClick={() => handleMonthChange(1)}>
          &gt;
        </button>
      </div>
      <div className="calendar">
        {["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"].map((day, index) => (
          <div key={index} className="day-header">
            {day}
          </div>
        ))}
        {Array.from(
          { length: new Date(selectedYear, selectedMonth, 1).getDay() || 7 },
          (_, i) => (
            <div key={`empty-${i}`} className="empty-day"></div>
          )
        )}
        {daysInMonth.map((day) => (
          <button
            key={day}
            className={`day ${
              day === selectedDate ? "selected" : ""
            } ${new Date(selectedYear, selectedMonth, day) < today ? "disabled" : ""}`}
            onClick={() => handleDateSelect(day)}
          >
            {day}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CustomDatePicker;
