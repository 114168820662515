import React, { useState } from "react";
import { motion } from "framer-motion";
import "./Questionnaire.css";

const questions = [
    {
        question: "Avez-vous déjà réalisé un audit de sécurité informatique au cours des 12 derniers mois ?",
        options: [
            { label: "Non", points: 0 },
            { label: "Oui", points: 1 },
        ],
    },
    {
        question: "Votre entreprise a-t-elle une politique de sécurité informatique documentée et partagée avec tous ?",
        options: [
            { label: "Non", points: 0 },
            { label: "Oui", points: 1 },
        ],
    },
    {
        question: "Utilisez-vous un pare-feu physique (firewall) pour protéger votre réseau informatique ?",
        options: [
            { label: "Non", points: 0 },
            { label: "Oui", points: 1 },
        ],
    },
    {
        question: "Mettez-vous régulièrement à jour vos systèmes d'exploitation et logiciels de sécurité ?",
        options: [
            { label: "Non", points: 0 },
            { label: "Oui", points: 1 },

        ],
    },
    {
        question: "Avez vous estimez le temps perdu lors d'un problème informatique ?",
        options: [
            { label: "Non", points: 0 },
            { label: "Oui", points: 1 },

        ],
    },
    {
        question: "Avez-vous mis en place une solution de sauvegarde régulière de vos données ?",
        options: [
            { label: "Non", points: 0 },
            { label: "Oui", points: 1 },

        ],
    },
    {
        question: "Votre entreprise a-t-elle un plan de reprise d'activité en cas d'incident majeur ?",
        options: [
            { label: "Non", points: 0 },
            { label: "Oui", points: 1 },

        ],
    },
    {
        question: "Utilisez-vous une solution de protection contre les virus et logiciels malveillants ?",
        options: [{
            label: "Non", points: 0
        },
        { label: "Oui", points: 1 },

        ],
    },
];

const getMessage = (score) => {
    if (score <= 3) {
        return "Votre entreprise est en train d'explorer les meilleures façons de sécuriser son environnement IT ? Com'Unity vous offre un diagnostic gratuit.";
    } else if (score <= 6) {
        return "Vous avez déjà déployé des mesures de sécurité, c'est excellent ! Souhaitez-vous échanger avec un expert Com'Unity ?";
    } else {
        return "Vous avez mis en place une stratégie de sécurité solide, bravo ! Aimeriez-vous en savoir plus sur les prochaines étapes ?";
    }
};

const Questionnaire = () => {
    const [hasStarted, setHasStarted] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [showResult, setShowResult] = useState(false);

    const handleAnswerClick = (points) => {
        setScore(score + points);

        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            setShowResult(true);
        }
    };

    return (
        <div className="security-quiz">
            <div className="quiz-content">
                {!hasStarted ? (
                    <motion.div
                        className="quiz-intro"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <img src="/img/logoSombre.png" alt="logo" className="quiz-intro-logo" />
                        <h2>Testez la sécurité de votre infrastructure IT</h2>
                        <p>Répondez à ces 8 questions et découvrez si votre entreprise est bien protégée contre les cybermenaces.</p>
                        <button className="start-button" onClick={() => setHasStarted(true)}>Commencer le test</button>
                    </motion.div>
                ) : showResult ? (
                    <>
                        <img src="/img/logoSombre.png" alt="logo" className="quiz-logo" />
                        <motion.div
                            className="quiz-result"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h2>Votre score de sécurité : {score} / 8</h2>
                            <p>{getMessage(score)}</p>
                        </motion.div>
                        <div></div>
                    </>
                ) : (
                    <>
                        <img src="/img/logoSombre.png" alt="logo" className="quiz-logo" />
                        <motion.div
                            key={currentQuestion}
                            className="quiz-question"
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 50 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h2>{currentQuestion + 1}. {questions[currentQuestion].question}</h2>
                            <div className="quiz-options">
                                {questions[currentQuestion].options.map((option, index) => (
                                    <button key={index} onClick={() => handleAnswerClick(option.points)}>
                                        {option.label}
                                    </button>
                                ))}
                            </div>
                        </motion.div>
                        <div className="quiz-step">{currentQuestion + 1}/8</div>
                    </>
                )}
            </div>
            <div className="quiz-image">
                <img src="/img/Capture.png" alt="Sécurité informatique" />
            </div>


        </div>
    );
};

export default Questionnaire;
