import ServiceTemplate from "../ServiceTemplate/ServiceTemplate";
import { useEffect, useState, useMemo } from "react";
import api from "../../api";

const InfogerancePage = () => {
    const [categoryData, setCategoryData] = useState(null);
    const [loading, setLoading] = useState(true);
    const staticInfogeranceData = {
        name: "Infogérance",
        definition: "L’infogérance est la délégation totale ou partielle de la gestion informatique à un prestataire externe.",
        points: [
            { point: "Éviter les coûts d’une équipe interne dédiée." },
            { point: "Bénéficier de technologies à jour sans investir en matériel." },
            { point: "Garantir une disponibilité et une sécurité optimale du système informatique." }
        ],
        reasons: [
            { title: "Réduction des coûts", description: "Économies jusqu’à 60 % sur les dépenses opérationnelles." },
            { title: "Sécurité renforcée", description: "70 % des cyberattaques ciblent les PME." },
            { title: "Accès à une expertise technique", description: "Compétences certifiées (Microsoft, Cisco, etc.)." },
            { title: "Gain de temps et flexibilité", description: "30 % du temps des dirigeants est perdu dans la gestion informatique." },
            { title: "Prévention des pannes", description: "Supervision 24/7 avec maintenance proactive." }
        ],
        valeurs: [{ title: "Support 24/7", content: "Une équipe d'experts toujours disponible." }]
    };

    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const response = await api.get("/service/infogerance");

                setCategoryData({
                    ...staticInfogeranceData,  // Données statiques
                    ...response.data,          // Données API (services, etc.)
                });
            } catch (error) {
                console.error("Erreur lors de la récupération des services :", error);

                setCategoryData(staticInfogeranceData);
            } finally {
                setLoading(false);
            }
        };
        fetchCategoryData();
    }, []);


    if (loading) return <p className="loading">Chargement...</p>;
    if (!categoryData) return <p className="error">Données introuvables.</p>;

    return (
        <ServiceTemplate isCategoryPage={true} staticData={categoryData} />
    );
};

export default InfogerancePage;
