import React, { useState } from "react";
import "./ArticlesCarousel.css";
import ScrollReveal from '../../ScrollReveal/ScrollReveal'
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

const ArticlesCarousel = ({ articles }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < articles.length - 3) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const isLastVisible = currentIndex + 3 >= articles.length;

  return (
    <div className="articles-carousel-container">
      <h2 className="articles-carousel-title">Toutes les actualités</h2>
      <div className="articles-carousel">
        {/* Flèche gauche */}
        {currentIndex > 0 && (
          <button className="carousel-arrow left-arrow" onClick={handlePrev}>
            <IoIosArrowBack />
          </button>
        )}
        <div className="articles-track">
          {articles.slice(currentIndex, currentIndex + 3).map((article) => (
            <div className="article-preview-card" key={article.id}>
              <ScrollReveal>
                <div className="article-preview-image">
                  <img
                    src={
                      article.Images && article.Images.length > 0
                        ? `http://192.168.77.149:5000${article.Images[0].imageUrl}`
                        : "./img/Capture.png" // Image par défaut si aucune image
                    }
                    alt={article.title}
                  />
                </div>
                <div className="article-preview-content">
                  <h3 className="article-preview-title-text">{article.title}</h3>
                  <p className="article-preview-excerpt">
                    {article.content.replace(/(<([^>]+)>)/gi, "").slice(0, 100)}...
                  </p>
                  <a href={`/article/${article.id}`} className="article-preview-read-more">
                    Lire la suite
                  </a>
                </div>
              </ScrollReveal>

            </div>
          ))}
        </div>
        {/* Flèche droite */}
        {!isLastVisible && (
          <button className="carousel-arrow right-arrow" onClick={handleNext}>
            <IoIosArrowForward />
          </button>
        )}
      </div>
    </div >
  );
};

export default ArticlesCarousel;
