import React, { useState } from "react";
import "./ContactCTA.css";

const ContactCTA = ({ isVisible }) => {
    const [formData, setFormData] = useState({
        company: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: ""
    });

    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Formulaire soumis :", formData);
        setSubmitted(true);
    };

    return (
        <div className={`contact-cta ${isVisible ? "" : "hidden"}`}>
            <div className="cta-content">
                <div>
                    <h2>Une question ?<br/> Besoin d'un devis ?</h2>
                    <p>Contactez-nous, nous vous répondrons rapidement.</p>
                </div>
            </div>

            {!submitted ? (
                <form onSubmit={handleSubmit} className="cta-form">
                    <input type="text" name="company" placeholder="Nom de l'entreprise *" value={formData.company} onChange={handleChange} required />
                    <input type="text" name="firstName" placeholder="Prénom *" value={formData.firstName} onChange={handleChange} required />
                    <input type="text" name="lastName" placeholder="Nom *" value={formData.lastName} onChange={handleChange} required />
                    <input type="email" name="email" placeholder="Adresse email *" value={formData.email} onChange={handleChange} required />
                    <input type="tel" name="phone" placeholder="Téléphone *" value={formData.phone} onChange={handleChange} required />
                    <textarea name="message" placeholder="Message (optionnel)" value={formData.message} onChange={handleChange}></textarea>
                    <button type="submit" className="submit-button">Envoyer</button>
                </form>
            ) : (
                <p className="success-message">Merci ! Nous vous contacterons bientôt.</p>
            )}
        </div>
    );
};

export default ContactCTA;
