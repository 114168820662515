import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import './Accueil.css';
import { Helmet } from "react-helmet";

// Composants internes
import ScrollReveal from "../ScrollReveal/ScrollReveal";
import Avis from "./Avis/Avis";
import FAQ from "./FAQ/FAQ";
import Expertise from "./Expertise/Expertise";
import AccueilItem from "./AccueilItem";
import CTA from "../cta/cta";
import RDV from "../RDV/RDV";
import ArticlesPreview from "./ArticleCarousel/ArticleCarousel";
import ScrollToAccueilItem from "../ScrollToAccueilItem/ScrollToAccueilItem";
import Questionnaire from "./Questionnaire/Questionnaire";

const Accueil = () => {
    const [isRdvOpen, setIsRdvOpen] = useState(false);
    const [rdvType, setRdvType] = useState('')
    const [recentArticles, setRecentArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchRecentArticles = async () => {
            try {
                const response = await api.get("/articles?sortOrder=desc");
                setRecentArticles(response.data);
            } catch (err) {
                console.error("Erreur lors de la récupération des articles :", err);
                setError("Impossible de charger les articles.");
            } finally {
                setLoading(false);
            }
        };
        fetchRecentArticles();
    }, []);

    const handleOpenRdv = (type) => {
        setRdvType(type)
        setIsRdvOpen(true);
    };
    const handleCloseRdv = () => {
        setIsRdvOpen(false);
    };

    return (
        <div className='accueil'>
            <Helmet>
                <title>Accueil - Votre Confiance Numérique</title>
                <meta name="description" content="Optimisez votre parc informatique avec nos services personnalisés en infogérance et cybersécurité." />
            </Helmet>
            <div className='bg-accueil'></div>
            <ScrollReveal>
                <div className='main'>
                    <h1>La Confiance Numérique au service de votre entreprise.</h1>
                    <Link className='alert'>Optimiser votre parc informatique</Link>
                </div>
                <ScrollToAccueilItem />
            </ScrollReveal>

            <div className='container-accueil'>
                <AccueilItem />
                <Expertise />
                <Questionnaire/>
                <FAQ />
                {loading ? (<p>Chargement des articles...</p>) : error ?
                    (<p>{error}</p>) :
                    (<ArticlesPreview articles={recentArticles} />)
                }
            </div>
            <CTA
                title="Besoin de conseils informatiques ?"
                subtitle="Prenez rendez-vous avec l'un de nos experts dès maintenant !"
                buttonLabel="Rendez-vous dans vos locaux"
                buttonLabel2="Rendez-vous en visioconférence"
                onButtonClick1={() => handleOpenRdv("physique")}
                onButtonClick2={() => handleOpenRdv("visio")}
            />
            <RDV isOpen={isRdvOpen} onClose={handleCloseRdv} appointmentType={rdvType} />
        </div>
    )
};

export default Accueil