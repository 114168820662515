import React from "react";
import Slider from "react-slick";
import "./PartnerCarousel.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PartnersCarousel = () => {
    const partners = [
        { id: 1, name: "Partner 1", logo: "/img/Capture.png" },
        { id: 2, name: "Partner 2", logo: "/img/Capture.png" },
        { id: 3, name: "Partner 3", logo: "/img/Capture.png" },
        { id: 4, name: "Partner 4", logo: "/img/Capture.png" },
        { id: 5, name: "Partner 5", logo: "/img/Capture.png" },
        { id: 6, name: "Partner 6", logo: "/img/Capture.png" },
        { id: 7, name: "Partner 7", logo: "/img/Capture.png" },
        { id: 8, name: "Partner 8", logo: "/img/Capture.png" },
    ];

    const settings = {
        infinite: true,
        speed: 250,  // Transition lente pour un effet fluide
        slidesToShow: 5,  // Nombre d'éléments visibles
        slidesToScroll: 1,  // Scroll lent pour un effet continu
        autoplay: true,
        autoplaySpeed: 3000,  // Pas de pause entre les slides
        cssEase: "linear",  // Défilement fluide
        arrows: false,  // Pas de flèches
        dots: false,  // Pas de points en bas
        pauseOnHover: false,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 4 } },
            { breakpoint: 768, settings: { slidesToShow: 3 } },
            { breakpoint: 480, settings: { slidesToShow: 2 } }
        ]
    };

    return (
        <div className="partners-carousel">
            <h2>Nos Partenaires</h2>
            <Slider {...settings}>
                {partners.map((partner) => (
                    <div key={partner.id} className="partner-slide">
                        <img src={partner.logo} alt={partner.name} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default PartnersCarousel;
