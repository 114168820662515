import "./ServicePage.css";
import React, { useState, useEffect } from "react";
import "./ServicePage.css";
import ScrollReveal from "../ScrollReveal/ScrollReveal";
import ContactCTA from "../ContactCTA/ContactCTA";
import ExpandableBlock from "./ExpandableBlock/ExpandableBlock";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'

const FibreTemplate = () => {
    const fibreStatique =
    {
        name: "Fibre Optique",
        fibre: [
            {
                name: "FTTH",
                definition: "",
                points: [
                    { point: "Offrir des débits Internet ultra-rapides aux particuliers et entreprises" },
                    { point: "Répondre aux besoins croissants en bande passante (streaming, cloud, etc.)" },
                ],
                reasons: [
                    { title: "Vitesse et performance", description: "Débits pouvant atteindre 1 Gbit/s" },
                    { title: "Fiabilité et stabilité", description: "7Connexion constante et de haute qualité, moins sensible aux perturbations externes" },
                    { title: "Capacité de mutualisation", description: "Partage d'une même fibre entre plusieurs utilisateurs, réduisant les coûts" },
                    { title: "Possibilités d'évolution", description: "Large bande passante permettant l'accès à des services avancés (téléphonie IP, visioconférence, cloud)" },
                    { title: "Coût attractif", description: "Alternative économique pour les PME par rapport à la fibre dédiée (FTTO).Alternative économique pour les PME par rapport à la fibre dédiée (FTTO)" }
                ],
                valeurs: [
                    { title: "Conseils personnalisés", description: "Des conseils personnalisés pour choisir l'offre la plus adaptée à votre activité" },
                    { title: "Fiabilité et stabilité", description: "Une installation professionnelle réalisée par nos équipes pour garantir une mise en service rapide et efficace." },
                    { title: "Support", description: "Un support technique réactif pour résoudre rapidement tout problème" },
                    { title: "Supervision", description: "Supervision de vos liens fibre optique par Com’Unity" },
                ]
            },
            {

                name: "FTTO",
                definition: "La fibre FTTO (Fiber To The Office) est une solution de connectivité haut débit dédiée aux entreprises. Contrairement à la FTTH, la FTTO offre une ligne fibre optique exclusivement réservée à l'entreprise, garantissant des performances optimales et une sécurité renforcée.",
                points: [
                    { point: "Répondre aux besoins spécifiques des entreprises en matière de connectivité." },
                    { point: "Assurer une bande passante garantie et symétrique." },
                    { point: "Offrir un niveau de service et de sécurité supérieur à la FTTH." },
                ],
                reasons: [
                    { title: "Débit garanti et symétrique", description: "Débits pouvant atteindre 1 Gbit/s \n Débits montants et descendants identiques, cruciaux pour le cloud et les visioconférences." },
                    { title: "Fiabilité et disponibilité maximales", description: "Taux de disponibilité garanti par contrat (souvent supérieur à 99,99%).Temps de rétablissement rapide en cas de panne (généralement moins de 4 heures)." },
                    { title: "Sécurité renforcée", description: "Ligne dédiée non partagée, réduisant les risques d'intrusion.Possibilité d'implémenter des protocoles de sécurité avancés." },
                    { title: "Evolutivité", description: "Facilité d'augmentation du débit sans changement d'infrastructure.Adaptabilité aux besoins croissants de l'entreprise." },
                    { title: "Support premium", description: "Suivi personnalisé et support technique dédié 24/7.Interventions prioritaires en cas de problème." }
                ],
                valeurs: [
                    { title: "Analyse", valeur: "Une analyse approfondie de vos besoins pour une solution parfaitement adaptée." },
                    { title: "Gestion", valeur: "Une gestion de projet de bout en bout, de l'étude à la mise en service." },
                    { title: "Accompagnement", valeur: "Un accompagnement continu pour optimiser votre infrastructure réseau." },
                ]
            }
        ],
        relatedServices: [
            {
                "name": "Fibre Optique",
                "slug": "fibre-optique",
                "definition": "La fibre FTTO (Fiber To The Office) est une solution de connectivité haut débit dédiée aux entreprises. Contrairement à la FTTH, la FTTO offre une ligne fibre optique exclusivement réservée à l'entreprise, garantissant des performances optimales et une sécurité renforcée."
            },
            {
                "name": "VPN & MPLS",
                "slug": "vpn-mpls",
                "definition": "Définition du VPN & MPLS"
            },
            {
                "name": "Messagerie",
                "slug": "messagerie",
                "definition": "Définition des services de messagerie"
            }
        ]
    }

    return (
        <div className="service-page">
            <Helmet>
                <title>Service - Tout pour répondre à votre besoin !</title>
                <meta name="description" content="Infogérance, matériel, fibre , hébergement, cybersécurité tous nos services afin de répondre à vos besoins informatique" />
            </Helmet>
            <div className="bg-service bg-fibre-optique"></div>
            <ScrollReveal>
                <div className="service-header">
                    <h1>{fibreStatique.name}</h1>
                </div>
            </ScrollReveal>

            <div className="service-container">
                {fibreStatique.fibre.map((data, index) => (
                    <div className="service-body">
                        <div className="service-def">
                            <h2>Qu'est-ce que {data.name} ?</h2>
                            <p>{data.definition}</p>
                            {data.points && (
                                <>
                                    <h3>Pourquoi ça existe ?</h3>
                                    <ul>
                                        {data.points.map((point, index) => (
                                            <li key={index}>{point.point}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </div>

                        {/* 🔥 Raisons de choisir ce service */}
                        {data.reasons && (
                            <div className="service-bloc">
                                <h2>Les avantages pour vous</h2>
                                <div className="reasons-grid">
                                    <div className="reasons-row-top">
                                        {data.reasons.slice(0, 3).map((reason, index) => (
                                            <div className="reasons-container">
                                                <img src="/img/Capture.png" alt="avantages" className="reasons-img" />
                                                <div key={index} className="reason-card">
                                                    <h3>{reason.title}</h3>
                                                    <p>{reason.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="reasons-row-bottom">
                                        {data.reasons.slice(3, 5).map((reason, index) => (
                                            <div className="reasons-container">
                                                <img src="/img/Capture.png" alt="avantages" className="reasons-img" />
                                                <div key={index} className="reason-card">
                                                    <h3>{reason.title}</h3>
                                                    <p>{reason.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* 📌 Liste Dépliable */}
                        <div className="service-valeurs-ajoute">
                            <h2>Nôtre valeur ajoutée</h2>
                            <p>{data.valeur_ajoutee}</p>
                            <ExpandableBlock items={data.valeurs} imageUrl={"/img/Capture.png"} />
                        </div>
                        <br />
                    </div>

                ))}

                {fibreStatique.relatedServices.map((related) => (
                    <div key={related.id} className="related-service">
                        <Link to={`/${related.slug}`} className="related-service-button">
                            {related.name}
                        </Link>
                        <p className="related-service-description">{related.description}</p>
                    </div>
                ))}

            </div>

            <ContactCTA isVisible={true} />
        </div>
    )

}

export default FibreTemplate