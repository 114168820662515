import React, { useState } from "react";
import "./CustomTimePicker.css";

const CustomTimePicker = ({ onTimeSelect,typeRdv }) => {
  const timesPhysique = [
    "10:30",
    "11:30",
    "12:30",
    "14:30",
    "15:30",
    "16:30"
  ];

  const timeVisio=[
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00"
  ]

  const times = typeRdv === "physique" ? timesPhysique : timeVisio

  const [selectedTime, setSelectedTime] = useState("");

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    onTimeSelect(time); // Passe l'heure sélectionnée au parent
  };

  return (
    <div className="custom-time-picker">
      <h2>Choisissez une heure :</h2>
      <div className="time-options">
        {times.map((time, index) => (
          <button
            key={index}
            className={`time-button ${typeRdv} ${time === selectedTime ? "selected" : ""}`}
            onClick={() => handleTimeSelect(time)}
          >
            {time}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CustomTimePicker;
