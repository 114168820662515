import React, { useState, useEffect } from "react";
import "./NavbarItem.css";
import { Link } from "react-router-dom";
import api from "../../api"; // Fichier pour gérer les appels API

const NavbarItem = () => {
    const [menuItems, setMenuItems] = useState([]);
    useEffect(() => {
        const fetchMenuData = async () => {
            try {
                const response = await api.get("/service"); // Récupération des catégories + services déjà structurés côté backend
                setMenuItems(response.data); // Met à jour l'état avec le format déjà prêt
            } catch (error) {
                console.error("Erreur lors de la récupération des services :", error);
            }
        };
        fetchMenuData();
    }, []);

    return (
        <>
            {menuItems.length > 0 ? (
                menuItems.map((item, index) => (
                    <div className="menu-item" key={index} >
                        {item.title==="Infogérance"?<Link to={'/infogerance'}>{item.title}</Link> : <p>{item.title}</p>}
                        {item.links.length > 0 && (
                            <div className="dropdown">
                                <div className={`dropdown-arrow arrow-${index}`}></div>
                                <div className="dropdown-content">
                                    {item.links.map((link, idx) => (
                                        <Link className="dropdown-link" key={idx} to={`/${link.slug}`}>
                                            <img className={`${link.label === "Fibre Optique" ? "reverse" : link.label === "VOIP Communication" ? "reverse":  link.label === "Sauvegarde"? "reverse" : ""}`}  src={link.img.substring(1,link.img.length)} alt={link.label} />
                                            <p>{link.label}</p>
                                            <div className="underline"></div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <p>Chargement des services...</p>
            )}
        </>
    );
};

export default NavbarItem;