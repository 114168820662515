import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ArticleList.css";
import ScrollReveal from '../../ScrollReveal/ScrollReveal'


const ArticleList = ({ filters, allArticles }) => {
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [visibleCount, setVisibleCount] = useState(8);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  useEffect(() => {
    const filterAndSortArticles = () => {
      let filtered = [...allArticles];

      // Filtrer par catégorie
      if (filters.searchQuery) {
        filtered = filtered.filter((article) =>
          article.title.toLowerCase().includes(filters.searchQuery.toLowerCase())
        );
      }
      if (filters.category) {
        filtered = filtered.filter(
          (article) =>
            article.category?.name &&
            article.category.name.toLowerCase() === filters.category.toLowerCase()
        );
      }

      // Filtrer par période
      if (filters.period) {
        const now = new Date();
        let startDate;

        switch (filters.period) {
          case "1-week":
            startDate = new Date(now.setDate(now.getDate() - 7));
            break;
          case "1-month":
            startDate = new Date(now.setMonth(now.getMonth() - 1));
            break;
          case "3-months":
            startDate = new Date(now.setMonth(now.getMonth() - 3));
            break;
          case "6-months":
            startDate = new Date(now.setMonth(now.getMonth() - 6));
            break;
          case "1-year":
            startDate = new Date(now.setFullYear(now.getFullYear() - 1));
            break;
          default:
            break;
        }

        if (startDate) {
          filtered = filtered.filter(
            (article) => new Date(article.createdAt) >= startDate
          );
        }
      }

      // Trier par date
      if (filters.sortOrder === "asc") {
        filtered = filtered.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      } else {
        filtered = filtered.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      }

      setFilteredArticles(filtered);
    };

    filterAndSortArticles();
  }, [filters, allArticles]);

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 4); // Affiche 5 articles supplémentaires
  };

  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return `${text.substring(0, limit)}...`;
    }
    return text;
  };

  return (
    <div className="article-list">
      {filteredArticles.length > 0 ? (
        filteredArticles.slice(0, visibleCount).map((article,index) => (
          <ScrollReveal threshold={0.10} className="actu-fade" key={index}>
            <div className="article-item" >
              <div className="article-header">
                {article.Images && article.Images[0] && (
                  <img
                    className="article-image"
                    src={`http://192.168.77.149:5000${article.Images[0].imageUrl}`}
                    alt="Article"
                  />
                )}
                <span className="article-category">{article.category?.name}</span>
              </div>
              <div className="article-content">
                <h2>{article.title}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: truncateText(
                      article.content.replace(/<\/?[^>]+(>|$)/g, ""),
                      150
                    ),
                  }}
                ></p>
              </div>
              <div className="article-footer">
                <Link to={`/article/${article.id}`} className="read-more">
                  Lire plus
                </Link>
                <span className="article-date">
                  {formatDate(article.createdAt)}
                </span>
              </div>
            </div>
          </ScrollReveal>
        ))
      ) : (
        <p className="no-articles-message">Aucun article ne correspond aux filtres sélectionnés.</p>
      )}
      <ScrollReveal className="actu-button-fade">
        {filteredArticles.length > visibleCount && (
          <button className="show-more-button" onClick={handleShowMore}>
            AFFICHER PLUS
          </button>
        )}
      </ScrollReveal>
    </div>
  );
};

export default ArticleList;
