import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../api";
import { Helmet } from "react-helmet";


import "./ArticleDetails.css";

const ArticleDetails = () => {
  const { id } = useParams(); // Récupère l'ID depuis l'URL
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await api.get(`/articles/${id}`);
        setArticle(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'article :", error);
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (!article) {
    return <p>Article introuvable.</p>;
  }

  return (
    <div className="article-details">
      <Helmet>
        <title>Blog - {article.title}</title>
        <meta name="description" content="Suivez nos acutalitées afin de rester au courant des nouvelles dans au sein de la cybersécurité" />
      </Helmet>
      <h1>{article.title}</h1>
      <div className="detail-container">
        {/* Affichage de l'image principale */}
        {article.Images && article.Images.length > 0 && (
          <img
            className="main-image"
            src={`http://localhost:5000${article.Images[0].imageUrl}`}
            alt="Illustration principale"
          />
        )}
        {/* Contenu de l'article */}
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: article.content }}
        ></div>
      </div>
    </div>
  );
};

export default ArticleDetails;
