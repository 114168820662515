import React, { useState } from "react";
import "./FAQ.css";
import ScrollReveal from "../../ScrollReveal/ScrollReveal";

const FAQ = () => {
  const questions = [
    {
      question: "Quels services proposez-vous ?",
      answer:
        "Nous proposons des solutions personnalisées pour l'infogérance, la cybersécurité, l'hébergement de serveurs, et bien plus encore.",
    },
    {
      question: "Comment planifier un rendez-vous ?",
      answer:
        "Vous pouvez planifier un rendez-vous via notre section dédiée en choisissant la date, l'heure et le type de rendez-vous.",
    },
    {
      question: "Quel est le délai de réponse ?",
      answer:
        "Nous nous engageons à répondre à toutes vos demandes dans un délai de 24 heures ouvrées.",
    },
    {
      question: "Est-ce que Mathieu est beau ?",
      answer:
        "En vérité, oui car la beauté réside surtout dans la personne plus que l'apparence et Mathieu est quelqu'un de très bien honnêtement.",
    },
    {
      question: "Quels sont vos délais d'installation ?",
      answer:
        "Les délais dépendent de la complexité du projet, mais nous visons toujours à être rapides et efficaces.",
    },
    {
      question: "Offrez-vous un support technique ?",
      answer:
        "Oui, notre support technique est disponible 24/7 pour résoudre vos problèmes rapidement.",
    },
    {
      question: "Comment protéger mes données ?",
      answer:
        "Nous intégrons les dernières technologies de cybersécurité pour protéger vos données sensibles.",
    },
    {
      question: "Fournissez-vous une documentation ?",
      answer:
        "Oui, nous fournissons une documentation complète pour chaque service ou solution que nous mettons en place.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq">
      <h2>Foire Aux Questions</h2>
      <div className="faq-container">
        <div className="faq-items">
          {questions.map((item, index) => (
            <ScrollReveal key={index}>
              <div className={`faq-item ${openIndex === index ? "open" : ""}`}>
                <button
                  className="faq-question"
                  onClick={() => toggleQuestion(index)}
                >
                  {item.question}
                  <span
                    className={`arrow ${openIndex === index ? "up" : "down"}`}
                  >
                    ▼
                  </span>
                </button>
                <div
                  className={`faq-answer ${
                    openIndex === index ? "expanding" : "collapsing"
                  }`}
                >
                  {item.answer}
                </div>
              </div>
            </ScrollReveal>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
