import { useEffect, useRef } from "react";
import "./ScrollReveal.css"

const ScrollReveal = ({ children, className = "fade-in", threshold = 0.5 }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible"); // Ajoute la classe visible
        }
      },
      { threshold } 
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <div ref={elementRef} className={`${className}`}>
      {children}
    </div>
  );
};

export default ScrollReveal;
