import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import api from "../../../api";
import "./CreateArticle.css";

const CreateArticle = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get("/categories");
        setCategories(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des catégories :", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("categoryId", categoryId);
    images.forEach((image) => {
      formData.append("images", image);
    });

    try {
      await api.post("/articles", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert("Article créé avec succès !");
    } catch (error) {
      console.error("Erreur lors de la création de l'article :", error);
    }
  };

  const handleEditorChange = (newContent) => {
    setContent(newContent);
  };

  const handleImageUpload = async (blobInfo, success, failure) => {
    try {
      const formData = new FormData();
      formData.append("image", blobInfo.blob());

      const response = await api.post("/upload", formData);

      if (response.status === 201) {
        const { url } = response.data;
        success(url); // Retourner l'URL pour l'afficher dans l'éditeur
      } else {
        failure("Échec de l'upload de l'image. Code HTTP invalide.");
      }
    } catch (error) {
      console.error("Erreur lors de l'upload de l'image :", error);
      failure("Erreur lors de l'upload de l'image.");
    }
  };

  return (
    <div className="create-article-container">
      <h1>Créer un Article</h1>
      <form onSubmit={handleSubmit} className="create-article-form">
        <div className="form-group">
          <label htmlFor="title">Titre</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Saisissez le titre de l'article"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="category">Catégorie</label>
          <select
            id="category"
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
            required
          >
            <option value="">Sélectionnez une catégorie</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="content">Contenu</label>
          <Editor
            apiKey="9nfd005teri8ygxky9ec60m1tw5jk3hghvt3lpvo3wr94ua3" // Clé API TinyMCE
            value={content}
            onEditorChange={handleEditorChange}
            init={{
              height: 400,
              menubar: true,
              plugins: "image code",
              toolbar:
                "undo redo | link image | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code | help | image",
              automatic_uploads: true,
              images_upload_handler: handleImageUpload, // Gestionnaire d'upload d'images
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="images">Images en-tête</label>
          <input
            type="file"
            id="images"
            multiple
            accept="image/*"
            onChange={(e) => setImages([...e.target.files])}
          />
        </div>
        <button type="submit" className="submit-button">
          Publier
        </button>
      </form>
    </div>
  );
};
export default CreateArticle;