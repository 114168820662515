import React from "react";
import "./NousConnaitre.css";
import ScrollReveal from "../ScrollReveal/ScrollReveal";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useState } from "react";
import PartnersCarousel from "./PartnerCarousel/PartnerCarousel.js";


const values = [
    { title: "Proximité ", desc: "Être proche de nos clients pour mieux comprendre vos besoins." },
    { title: "Réactivité", desc: "Intervention rapide sur site ou à distance." },
    { title: "Expertise ", desc: "Accompagnement sur mesure avec des solutions innovantes et sécurisées." },
    { title: "Éthique", desc: " Collaboration et transparence pour bâtir des relations de confiance." }
];

const certifications = [
    { name: "ISO 27001", icon: "/img/Capture.png" },
    { name: "Microsoft Certified", icon: "/img/Capture.png" },
    { name: "AWS Certified", icon: "/img/Capture.png" },
    { name: "Cisco CCNA", icon: "/img/Capture.png" }
];
const stats = [
    { label: "Temps moyen de résolution (min)", value: 45 },
    { label: "Clients satisfaits (%)", value: 97 },
    { label: "Création de la société", value: 2015 },
    { label: "Tickets résolus en 2024", value: 1738 }
];

const NousConnaitre = () => {
    const [counterOn, setCounterOn] = useState(false);
    return (
        <div className="nous-connaitre">
            <div className="bg-nous"></div>
            <ScrollReveal>
                <div className="presentation">
                    <img src="/img/logo500x500.png" />
                    <div>
                        <h1>Nous Connaître</h1>
                        <p>
                            Chez Com'Unity, nous transformons la complexité informatique en un atout stratégique pour que vous puissiez vous concentrer sur l'essentiel : 
                            <br /><b>le développement de votre activité</b>.
                            <br />
                            <br />
                            Spécialisés dans l'infogérance de proximité et les solutions informatiques, nous accompagnons les entreprises dans leur transformation numérique.
                            Véritable spécialiste des solutions informatiques pour les TPE – PME, bénéficiez-vous aussi de <b> solutions personnalisées </b>en fonction des exigences spécifiques de votre entreprise et de votre marché.
                            <br />
                            <br />
                            <i>Nous connaître c’est nous essayer.</i>
                        </p>
                    </div>
                </div>
            </ScrollReveal>

            <ScrollReveal>
                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                    <div className="stats-container">
                        {stats.map((stat, index) => (
                            <div key={index} className="stat-card">
                                
                                <h3>
                                    {counterOn && stat.label !== "Création de la société" ? <CountUp start={0} end={stat.value} duration={3} delay={0.2} /> :<span>2015</span>}
                                </h3>
                                <p>{stat.label}</p>
                            </div>
                        ))}
                    </div>
                </ScrollTrigger>
            </ScrollReveal>

            <ScrollReveal>
                <div className="valeurs">
                    <h2>Nos Valeurs</h2>
                    <div className="valeurs-container">
                        {values.map((value, index) => (
                            <div key={index} className="valeur-card">
                                <h3>{value.title}</h3>
                                <p>{value.desc}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </ScrollReveal>

            <PartnersCarousel />

            <ScrollReveal>
                <div className="certifications">
                    <h2>Nos Certifications</h2>
                    <div className="certifications-container">
                        {certifications.map((certif, index) => (
                            <div key={index} className="certif-card">
                                <img src={certif.icon} alt={certif.name} />
                                <p>{certif.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </ScrollReveal>
        </div>
    );
};



export default NousConnaitre;
