import React, { useState, useEffect, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./ServiceGraph.css";
import { useLocation } from "react-router-dom";

const ServiceGraph = ({ services }) => {
    const [selectedService, setSelectedService] = useState(null);
    const [hoveredService, setHoveredService] = useState(null);
    const [direction, setDirection] = useState(1);
    const location = useLocation();


    const suiviConseil = {
        id: "suivi-conseils",
        name: "Suivi et conseils",
        definition: "En complément de ces 4 étapes, l’infogérance assure un rôle de conseil :",
        description: "L'objectif est d'obtenir une vision globale et précise de votre l'infrastructure informatique.",
        image: "/img/Capture.png",
        slug: "#suivi-conseils",
        points: [
            { point: "Accompagnement dans l'évolution de vos besoins" },
            { point: "Contrôle annuel sur site de votre parc informatique" },
        ],
    };

    // 🔥 Crée une version **immuable** du tableau avec `useMemo`
    const servicesWithStatic = useMemo(() => [...services, suiviConseil], [services]);

    // ✅ Sélectionner le premier service dès que `servicesWithStatic` change
    useEffect(() => {
        if (servicesWithStatic.length > 0 && !selectedService) {
            setSelectedService(servicesWithStatic[0]);
        }
    }, [servicesWithStatic, selectedService]);

    // ✅ Gérer le hash dans l'URL pour faire défiler vers le bon service
    useEffect(() => {
        if (location.hash && servicesWithStatic.length > 0) {
            const serviceId = location.hash.substring(1); // Supprime le "#"
            const foundService = servicesWithStatic.find(
                (service) => service.slug.substring(service.slug.indexOf("#") + 1) === serviceId
            );

            if (foundService && foundService !== selectedService) {
                setSelectedService(foundService);
                setTimeout(() => {
                    const element = document.getElementById(serviceId);
                    if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
                }, 100);
            }
        }
    }, [location.hash, servicesWithStatic]);

    // ✅ Fonction pour gérer le changement de service
    const handleServiceChange = (service) => {
        if (selectedService && selectedService.id !== service.id) {
            const newIndex = servicesWithStatic.findIndex((s) => s.id === service.id);
            const oldIndex = servicesWithStatic.findIndex((s) => s.id === selectedService.id);
            setDirection(newIndex > oldIndex ? 1 : -1);
            setSelectedService(service);
        }
    };

    // 🎯 Positionnement des services en étoile
    const positions = servicesWithStatic.map((_, index) => {
        const angle = (index / servicesWithStatic.length) * Math.PI * 2;
        const x = Math.cos(angle) * 160;
        const y = Math.sin(angle) * 160;
        return { x, y };
    });

    return (
        <div className="service-graph">
            <div className="service-graph-content">
                <div className="arc-background"></div>

                {/* 🌟 Arc de cercle des services */}
                <div className="service-graph-arc">
                    {servicesWithStatic.map((service, index) => {
                        const { x, y } = positions[index];
                        return (
                            <motion.img
                                key={service.id}
                                src="/img/Capture.png"
                                alt={service.name}
                                className={selectedService?.id === service.id ? "active" : ""}
                                onClick={() => handleServiceChange(service)}
                                onMouseEnter={() => setHoveredService(service.id)}
                                onMouseLeave={() => setHoveredService(null)}
                                animate={{
                                    transform: `translate(${x}px, ${y}px) scale(${selectedService?.id === service.id ? 1.5 : hoveredService === service.id ? 1.2 : 1})`
                                }}
                                transition={{ duration: 0.3 }}
                            />
                        );
                    })}
                </div>

                {/* 📝 Informations du service sélectionné */}
                <AnimatePresence mode="wait" custom={direction}>
                    {selectedService && (
                        <motion.div
                            id={selectedService.slug.substring(selectedService.slug.indexOf("#") + 1)}
                            key={selectedService.id}
                            className="service-graph-info"
                            initial={{ opacity: 0, x: direction * 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: direction * -100 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h2>{selectedService.name}</h2>
                            <p className="service-description">{selectedService.definition}</p>
                            <ul className="service-list">
                                {selectedService?.points?.map((point, index) => (
                                    <li key={index}>{point.point}</li>
                                ))}
                            </ul>
                            <p className="service-detail">{selectedService.description}</p>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default ServiceGraph;
