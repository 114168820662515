import React, { useState } from "react";
import "./ExpandableBlock.css";

const ExpandableBlock = ({ items, imageUrl }) => {
    const [openIndex, setOpenIndex] = useState(null);
    const toggleItem = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
            <div className="expandable-block">
                <div className="expandable-content">
                    {items.map((item, index) => (
                        <div key={index} className={`expandable-item ${openIndex === index ? "open" : ""}`}>
                            <button className="expandable-title" onClick={() => toggleItem(index)}>
                                {item.title}
                                <span className={`arrow ${openIndex === index ? "up" : "down"}`}>▼</span>
                            </button>
                            {openIndex === index && (
                                <div className="expandable-text">
                                    <p>{item.valeur}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* 🖼️ Partie Droite : Image */}
                <div className="expandable-image">
                    <img src={imageUrl} alt="Illustration" />
                </div>
            </div>
    );
};
export default ExpandableBlock;
