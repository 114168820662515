import React, { useState, useEffect, useCallback } from "react";
import "./Blog.css";
import ArticleList from "./Article/ArticleList";
import FilterBar from "./Article/FilterBar";
import SearchBar from "./Article/SearchBar";
import api from "../../api";
import ScrollReveal from "../ScrollReveal/ScrollReveal";
import { Helmet } from "react-helmet";

const Blog = () => {
  const [categories, setCategories] = useState([]);
  const [allArticles, setAllArticles] = useState([]);
  const [filters, setFilters] = useState({
    category: "",
    period: "",
    sortOrder: "desc",
    searchQuery: ""
  });

  useEffect(() => {
    const fetchCategoriesAndArticles = async () => {
      try {
        const [categoriesResponse, articlesResponse] = await Promise.all([
          api.get("/categories"),
          api.get("/articles"),
        ]);
        setCategories(categoriesResponse.data);
        setAllArticles(articlesResponse.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchCategoriesAndArticles();
  }, []);

  // Mémorisation de la fonction onFilterChange
  const handleFilterChange = useCallback(
    (newFilters) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...newFilters,
      }));
    },
    [] // Cette fonction ne change jamais
  );

  const handleSearchChange = (query) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchQuery: query,
    }));
  };

  return (

    <div className="actualité">
      <Helmet>
        <title>Blog - Nos actualités</title>
        <meta name="description" content="Suivez nos acutalitées afin de rester au courant des nouvelles dans au sein de la cybersécurité" />
      </Helmet>
      <div className='bg-actu'></div>
      <ScrollReveal>
        <div className="main-actu">
          <h1>Actualités IT</h1>
          <p>Voici nos Actualités du moments que nous redigeons !</p>
          <SearchBar
            searchQuery={filters.searchQuery}
            onSearchChange={handleSearchChange}
          />
        </div>
      </ScrollReveal>
      <div>
        <FilterBar categories={categories} onFilterChange={handleFilterChange} />
        <div className="blog">
          <div className="posts">
            <ArticleList filters={filters} allArticles={allArticles} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
