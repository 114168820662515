import React from "react";
import './Searchbar.css'
const SearchBar = ({ searchQuery, onSearchChange }) => {
  return (
    <div className="search-bar">
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => onSearchChange(e.target.value)}
        placeholder="Rechercher le sujet qui vous intéresse !"
      />
    </div>
  );
};

export default SearchBar;
