import Navbar from './component/Navbar/Navbar'
import Blog from './component/Blog/Blog'
import Accueil from './component/Accueil/Accueil'
import CreateArticle from './component/Blog/Article/CreateArticle'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import ArticleDetails from './component/Blog/Article/ArticleDetails'
import ServicePage from './component/ServiceTemplate/ServicePage'
/* Importez dans votre fichier CSS global ou directement dans le composant */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BackToTop from './component/BackToTop/BackToTop'
import InfogerancePage from './component/ServiceTemplate/InfogerancePage'
import NousConnaitre from './component/NousConnaitre/NousConnaitre'
import FibrePage from './component/ServiceTemplate/FibrePage'
function App() {

  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/actualite" element={<Blog />} />
          <Route path="/article/:id" element={<ArticleDetails />} />
          <Route path="/article/creation" element={<CreateArticle />} />
          <Route path="/infogerance" element={<InfogerancePage/>} />
          <Route path="/fibre-optique" element={<FibrePage/>} />
          <Route path='/nous-connaitre' element={<NousConnaitre/>}/>
          <Route path='/:slug' element={<ServicePage/>} />
        </Routes>
      </Router>
      <BackToTop/>
    </>
  );
}

export default App;
