import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa"; // Icône pour la flèche
import "./BackToTop.css";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Gestion de la visibilité de la flèche
  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Gestion du clic sur la flèche
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <button className={`back-to-top${isVisible ? " visible" : ""}`} onClick={scrollToTop}>
        <FaArrowUp />
      </button>
    </div>
  );
};

export default BackToTop;
