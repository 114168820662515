import React from "react";
import "./CTA.css";

const CTA = ({ title, subtitle, buttonLabel,buttonLabel2, onButtonClick1 = '', onButtonClick2 }) => {
  return (
    <div className="cta-bar">
      <div className="cta-content">
        <div className="cta-text">
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>
        <div className="cta-container-button">
          {onButtonClick1 !== '' && (
            <button className="cta-button" onClick={onButtonClick1}>
              {buttonLabel}
            </button>)}
          {onButtonClick2 !== '' && (
            <button className="cta-button" onClick={onButtonClick2}>
              {buttonLabel2}
            </button>)}
        </div>
      </div>
    </div>
  );
};

export default CTA;
