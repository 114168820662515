import React from "react";
import "./Expertise.css";
import ScrollReveal from "../../ScrollReveal/ScrollReveal";


const Expertise = () => {
  const expertiseItems = [
    { title: "Solution Personnalisable", desc: "Nous proposons des services totalement personnalisés qui vous permettent de vous concentrer sur votre cœur de métier." },
    { title: "Sécurité Renforcée", desc: "Nos solutions intègrent les dernières technologies pour garantir la sécurité de vos données." },
    { title: "Support Réactif", desc: "Notre équipe est disponible 24/7 pour vous accompagner dans vos projets." },
    { title: "Performance Optimisée", desc: "Nous optimisons vos infrastructures pour des performances maximales." },
    { title: "Solutions Innovantes", desc: "Nos experts travaillent sur des solutions à la pointe de la technologie." },
    { title: "Accompagnement Global", desc: "De l'audit à la maintenance, nous couvrons tout votre cycle IT." },
  ];

  return (
    <div className="expertise-container">
      <ScrollReveal threshold={0.30}>
        <div className="expertise-image">
          <img loading="lazy" src="./img/Accueil/expertise.jpg" alt="Illustration expertise" />
        </div>
      </ScrollReveal>
      <div className="expertise-content">
        <h2 className="expertise-title">L'Expertise Com'Unity</h2>

        <div className="expertise-text">
          <div className="text-columns">
            <div className="column">
              {expertiseItems.slice(0, 3).map((item, index) => (
                <ScrollReveal threshold={0.30} key={index}>
                  <div className="expertise-card" >
                    <h3>{item.title}</h3>
                    <p>{item.desc}</p>
                  </div>
                </ScrollReveal>

              ))}
            </div>
            <div className="column">
              {expertiseItems.slice(3).map((item, index) => (
                <ScrollReveal threshold={0.30} key={index}>
                  <div className="expertise-card">
                    <h3>{item.title}</h3>
                    <p>{item.desc}</p>
                  </div>
                </ScrollReveal>
              ))}
            </div>
          </div>
          <ScrollReveal>
            <button className="contact-button">Nous Contacter</button>
          </ScrollReveal>
        </div>
      </div>
    </div>
  );
};

export default Expertise;
