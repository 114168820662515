import { useState } from "react";
import "./Navbar.css";
import NavbarItem from "./NavbarItem";
import { Link } from 'react-router-dom'
import { FaLinkedin } from "react-icons/fa";


const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="main-nav" aria-label="Menu principal">
      <Link className="logo" to="/">
        <img src="/img/logoSombre.png" alt="Com'Unity" loading="lazy"/>
      </Link>
      <button className="burger" onClick={toggleMenu}>
        {/* Icône burger */}
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </button>
      <div className={`navigation ${isMenuOpen ? "open" : "ferme"}`}>
        <div className="bas">
          <NavbarItem />
        </div>
        <div className="haut">
          <div className="gauche">
            <Link to="/nous-connaitre">Nous connaître</Link>
            <a href="#lorem">Nous rejoindre</a>
            <Link className="actu alert-tri" to="/actualite">Actualité IT</Link>
          </div>
          <div className="droite">
            <Link className="contact alert-secondary">Nous Contacter</Link>
            <Link className="supp primary-button" target="_blan" to="https://com-unity.rmmservice.eu/">Espace Client</Link>
            <Link target="_blan" to="https://www.linkedin.com/company/com-unity-informatique/"><FaLinkedin className="linkedin" /></Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
