import React, { useState, useEffect } from "react";
import "./FilterBar.css";

const FilterBar = ({ categories, onFilterChange }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    onFilterChange({
      category: selectedCategory,
      period: selectedPeriod,
      sortOrder,
    });
  }, [selectedCategory, selectedPeriod, sortOrder, onFilterChange]);

  return (
    <div className="filter-bar">
      {/* Boutons des catégories */}
      <div className="filter-item categories">
        <div className={`category-button ${selectedCategory === "" ? "active" : ""}`}>
          <button onClick={() => setSelectedCategory("")}>
            Tout
          </button>
          <div className="underline"></div>
        </div>
        {categories.map((category, index) => (
          <div key={index} className={`category-button ${selectedCategory === category.name ? "active" : ""}`}>
            <button key={category.id} onClick={() => setSelectedCategory(selectedCategory === category.name ? "" : category.name)}>
              {category.name}
            </button>
            <div className="underline"></div>
          </div>
        ))}
        <div className="category-button">
          <select id="period" value={selectedPeriod} onChange={(e) => setSelectedPeriod(e.target.value)}>
            <option value="">Toutes les Périodes</option>
            <option value="1-week">1 semaine</option>
            <option value="1-month">1 mois</option>
            <option value="3-months">3 mois</option>
            <option value="6-months">6 mois</option>
            <option value="1-year">1 an</option>
          </select>
          <div className="underline"></div>
        </div>

        <div className="category-button">
          <select id="sortOrder" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value="desc">Date (plus récent)</option>
            <option value="asc">Date (plus ancien)</option>
          </select>
          <div className="underline"></div>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
