import React from "react";
import ScrollReveal from "../ScrollReveal/ScrollReveal";

const AccueilItem = () => {
    const accueilItems = [
        { icon: "audit", title: "Audit Informatique" },
        { icon: "infogérance", title: "Infogérance" },
        { icon: "logiciel", title: "Matériel & Logiciel" },
        { icon: "hébergement", title: "Hébergement Serveur" },
        
        { icon: "def2", title: "Internet" },
        { icon: "telecom", title: "Télécom" },
        { icon: "cybersécurité", title: "Cybersécurité" },
        { icon: "métier", title: "Application Métier" },
    ];

    return (
        <div id="service" className="service">
            <h2>Nos Services</h2>
            <div className="solution">
                {accueilItems.map(({ icon, title }, index) => (
                    <ScrollReveal key={index}>
                        <div className="card">
                            <img
                                loading="lazy"
                                src={`/img/icons/logo-${icon}.svg`}
                                alt={`logo-${title}`}
                                className="card-icon"
                            />
                            <h3 className="card-title">{title}</h3>
                            <div className="underline"></div>
                        </div>
                    </ScrollReveal>
                ))}
            </div>
        </div>
    );
};

export default AccueilItem;
