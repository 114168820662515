import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import './ScrollToAccueilItem.css'
const ScrollToAccueilItem = () => {
    const scrollToSection = () => {
        const section = document.getElementById("service");
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <button className="scroll-to-accueil-item" onClick={scrollToSection}>
            <IoIosArrowDown />
        </button>
    );
};

export default ScrollToAccueilItem;